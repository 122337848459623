<template>
  <div class="content">
    <div class="row" style="background: #2f2f2f ;">
      <section class="text-center col-lg-8 col-sm-12">


        <div
          class="header text-center"
          style=" width: 100%; background-color: #0d0d0e"
        >

          <div
            class="centered"
            style="width: 100%; ">

            <div class="text-center" style="padding: 20px;">
              <ResponsiveImage :image="djImage" clamp wl="500" hl="214" ></ResponsiveImage>
              <h1 class="tulum-party-title-alt" style="margin-top: -30px;font-size: 3rem;text-shadow: mediumaquamarine 4px 1px 10px 0">DJ SCHEDULE</h1>
              <h2 style="margin-top: -30px;text-shadow: mediumaquamarine 4px 1px 10px 0">Tulum, Mexico</h2>

              <p class="font-weight-800" style="font-size: 1rem">tulum.party</p>
              <p style="font-weight: normal; font-size: 10px;margin-top: -5px">
                DO NOT MISS A THING !
              </p>



              <hr style="height: 1px;background-color: #262424">

              <p style=" !important;padding: 15px" :style="{backgroundColor: secondaryColor}">
                <strong>Tulum</strong> is one of the biggest clusters of
                <router-link :to="{path: '/djs'}">Djs and Artists</router-link>
                in all of the continent.
                Few places truly square up to Tulum in terms of its exclusivity and range of prolific high-profile Djs
                and Producers showing up in town
                <router-link :to="{path:'/tulum-party-mexico-event-calendar'}">week by week</router-link>
                .
              </p>
              <p style="margin-top: 20px">Check out the upcoming artist lineup below.</p>
            </div>

          </div>
          <div class="card" style="padding: 40px">
            <HotThisWeek
              align="text-center"
              :secondary-color="secondaryColor" />
          </div>
        </div>

        <div
          class="row row-no-gutters"
          :style="{
              background: `linear-gradient(${secondaryColor}, black)`,
            }">

          <h4 class="section-header">Full Schedule</h4>

          <br />
          <div
            class="col-12 text-center"
            style="margin-top: 10px"
            :key="date"
            v-for="(period, date) in periodDates"
          >
            <div>
              <p
                style="
                background-color: #2eafb4;
                text-transform: uppercase;
                font-weight: bold;
                margin-bottom: 0;
              "
              >
                {{ getMonthShort(period[0].metadata.date + 'T' + '00:00') }}
              </p>
              <p
                style="
                color: silver !important;
                font-size: 1.4rem;
                background-color: black;
                font-weight: bold;
              "
              >
                {{ getDayNumeric(period[0].metadata.date + 'T' + '00:00') }}
              </p>
            </div>

            <div v-for="(djSchedule) in period" :key="djSchedule.slug" style="margin: 10px">


              <div class="card" v-if="djSchedule.metadata.highlight">
                <router-link :to="{path: djSchedule.metadata.artist_link}">

                  <hr>
                  <ResponsiveImage
                    hl="500"
                    wl="500"
                    hs="300"
                    ws="300"
                    clamp
                    :image="djSchedule.metadata.image_url">
                  </ResponsiveImage>
                  <h3 style="padding-top: 15px">{{ djSchedule.title.split('@')[0] }}</h3>
                </router-link>

              </div>

              <MiniArtistCard
                v-if="!djSchedule.metadata.highlight"
                :image="djSchedule.metadata.image_url"
                with-venue="true"
                :event="djSchedule"
                :key="djSchedule.slug"
              ></MiniArtistCard>
            </div>
          </div>
        </div>
        <br />
        <br />
        <small>Total: {{ djSchedule.length }}</small>
        <br />
        <br />
        <br />
        <BrandCollection></BrandCollection>
      </section>
      <aside class="card col-4 col-lg-4 d-none d-lg-inline">
        <SmartMenu :links="links" :logo="this.astroImg" ctaText="INFO" top-section-name="GET IN TOUCH"
                   :primary-color="this.primaryColor">
          <div class="row">
            <div class="col-sm-12 text-center">

              <p>
                <strong>Do you need any recommendation</strong> based on your musical taste?
                <br>
                Drop us a line <br>we'll make you dance.
              </p>
              <hr>
            </div>
          </div>


        </SmartMenu>

        <BrandCollection></BrandCollection>
      </aside>

    </div>

  </div>
</template>

<script>
import moment from 'moment';
import MiniArtistCard from '@/components/Cards/MiniArtistCard';
import BrandCollection from '@/components/Cards/BrandCollection';
import SmartMenu from '@/components/SmartMenu/SmartMenu';
import HotThisWeek from '@/pages/TulumDjSchedule/HotThisWeek';
import ResponsiveImage from '@/components/HeadImage/ResponsiveImage';
import { eventDateUtils } from '@/common/eventDateUtils';

export default {
  name: 'DjSchedule',
  mixins: [eventDateUtils],
  data: () => {
    return {
      showPalms: false,
      mainImage: 'https://imgix.cosmicjs.com/96fee0a0-094a-11ee-9fa8-55f9b865bb53-Sasha-Mia-Tulum.jpg',
      primaryColor: '#1C1C1F',
      secondaryColor: '#181818',
      showCalendarTab: false,
      highlight: { title: 'loading', metadata: { main_image: '', djschedule: { title: 'loading', metadata: {} } } },
      showEventsTab: true,
      period: 'year',
      collapsed: false,
      periodPartiesDates: [],
      listView: true,
      periodParties: [{ title: 'LOADING', metadata: { main_image: {} } }],
      calImage:
        'https://image.lexica.art/full_webp/4e714d4e-c8c4-4522-8755-c785bcb4746c',
      sanDJ: 'https://imgix.cosmicjs.com/f9568ec0-4160-11ef-a504-63e081e4680f-santo-dj.jpg',
      astroImg:
        'https://imgix.cosmicjs.com/1098dee0-e8ea-11ef-b333-e101bec29f3d-atro-transparent.png',
      gCalImg:
        'https://imgix.cosmicjs.com/a582d0a0-8483-11ee-9ebe-eb22b0517bf0-image.png',
      birdsImg:
        'https://imgix.cosmicjs.com/63c3dfe0-9b0f-11ee-b34c-99d1d89e42eb-image.png',
      djImage:
        'https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png',
      musicIsHope:
        'https://imgix.cosmicjs.com/32f88b10-c704-11ee-9ce5-59949019255e-music-is-hope.jpg',
      bgPalm:
        'https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png'
    };
  },
  metaInfo() {
    return {
      title: `Tulum DJ Schedule: Who's Playing When & Where`,
      meta: [
        {
          name: 'description',
          content: `Don't miss a beat! See the hottest DJs spinning in Tulum this week. Our Tulum DJ schedule shows you who's playing, when, and where.`
        },
        {
          name: 'keywords',
          content: `Tulum dj schedule, events, calendar, clubs, party agenda, dj agenda`
        },
        {
          property: 'og:title',
          content: `Don't Miss Out! Upcoming Tulum DJ Events`
        },
        {
          property: 'og:url',
          content: `https://www.tulum.party/tulum-dj-schedule`
        },
        {
          property: 'og:description',
          content: `Don't miss a beat! See the hottest DJs spinning in Tulum this week. Our Tulum DJ schedule shows you who's playing, when, and where.`
        },
        { property: 'og:type', content: 'website' }
      ]
    };
  },
  components: {
    ResponsiveImage,
    HotThisWeek,
    BrandCollection,
    SmartMenu,
    MiniArtistCard
  },
  computed: {
    djSchedule: {
      get() {
        function isIncomingParty(x) {
          let partyDateEndOfDay = moment.utc(x.metadata.date).endOf('day');

          let now = moment().startOf('day');
          return partyDateEndOfDay >= now;
        }

        return [...this.$store.state.djSchedule]
          .sort((a, b) => {
            let time_a = a.metadata.start_time || '00:00';
            let time_b = b.metadata.start_time || '00:00';
            return moment.utc(a.metadata.date + ' ' + time_a) >
            moment.utc(b.metadata.date + ' ' + time_b)
              ? 1
              : -1;
          })
          .filter((x) => isIncomingParty(x));
      }
    },
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      }
    },
    periodDates: {
      get() {
        let _periodDates = {};
        this.djSchedule.forEach((y) => {
          if (_periodDates[y.metadata.date]) {
            _periodDates[y.metadata.date].push(y);
          } else {
            _periodDates[y.metadata.date] = [];
            _periodDates[y.metadata.date].push(y);
          }
        });
        return _periodDates;
      }
    }
  },
  mounted: function() {
    moment.updateLocale('en', {
      week: {
        dow: 1 // Monday is the first day of the week.
      }
    });
    this.$store.dispatch('getDjSchedule').then((_x) => {
      // this.getThisPeriod('week', 'This Week', true);
    });
    this.$store.dispatch('getLinks', { slug: 'tulum-dj-schedule' });
    setTimeout(() => {
      this.showPalms = true;
    }, 3000);
  },
  methods: {
    // getDay: function getYear(date) {
    //   return moment(date, true).format('ddd');
    // },
    // getDay2: function getDay2(date) {
    //   return moment(date, true).format('DD');
    // },
    // getDay3: function getDay3(date) {
    //   return moment(date, true).format('MMM');
    // },
    googleCalendar() {
      window.ga('send', 'event', 'Event', 'Calendar', 'Google Calendar', 1);
      this.$rollbar.info(`Google Calendar Open`);
    }
  }
};
</script>
